/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
@import url(~primeicons/primeicons.css);
@import url(~primeng/resources/primeng.min.css);
@import url(~primeng/resources/themes/saga-blue/theme.css);
@import url(~@ng-select/ng-select/themes/default.theme.css);
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}

.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%;
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}

.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}

.mat-list-base .mat-list-option {
  font-size: 16px;
}

.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}

.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}

@keyframes cdk-text-field-autofill-end {
  /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #3f51b5;
}

.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}

.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}

.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}

.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}

.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}

.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}

.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}

.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}

.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}

.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*='mat-elevation-z']), .mat-flat-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-raised-button[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*='mat-elevation-z']), .mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']), .mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-fab[disabled]:not([class*='mat-elevation-z']), .mat-mini-fab[disabled]:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-card.mat-card-flat:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}

.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3f51b5;
}

.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}

.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
.mat-expansion-panel-header:hover {
    background: white;
  }
}

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26);
}

.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #3f51b5;
}

.mat-icon.mat-accent {
  color: #ff4081;
}

.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3f51b5;
}

.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}

.mat-menu-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
  fill: #c5cae9;
}

.mat-progress-bar-buffer {
  background-color: #c5cae9;
}

.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ff80ab;
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ff80ab;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}

.mat-select-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer.mat-drawer-push {
  background-color: white;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir='rtl'] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir='rtl'] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}

.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}

.mat-accent .mat-slider-thumb-label-text {
  color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}

.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #3f51b5;
}

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ff4081;
}

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}

.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}

.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}

.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}

.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}

.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

.light-style .sidenav .app-brand.demo {
  height: 66px;
}

.dark-style .sidenav .app-brand.demo {
  height: 66px;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.app-brand-logo.demo {
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.app-brand-logo.demo svg {
  width: 20px;
  height: 11px;
}

.app-brand-text.demo {
  font-size: 1.1rem;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}

@media (min-width: 992px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none;
  }
}

/* *****************************************************************************
 * Navbar
 */
.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: .5rem;
    width: 22rem;
  }
  .demo-navbar-user .dropdown-menu {
    margin-top: .25rem;
  }
}

/* *****************************************************************************
 * Content
 */
.demo-vertical-spacing > * + * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-sm > * + * {
  margin-top: .9375rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-lg > * + * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}

.demo-inline-spacing > *,
.demo-paragraph-spacing > p > * {
  margin: 0 .375rem .9375rem 0 !important;
}

.demo-paragraph-spacing > p {
  margin: 0;
}

.demo-blocks > * {
  display: block !important;
}

html:not(.material-style) .material-style-only {
  display: none !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir=rtl] .rtl-only {
  display: block !important;
}

/* *****************************************************************************
 * Layouts
 */
.layout-example-block {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.04);
  color: #c7254e;
}

.dark-style .layout-example-block {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
}

.layout-example-block code {
  background: none;
  color: #666;
}

.dark-style .layout-example-block code {
  color: #fff;
}

.layout-example-block-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 500px;
}

.layout-example-block-sidenav {
  width: 24%;
  background: #607d8b !important;
}

.layout-example-block-sidenav code {
  color: #fff;
}

.layout-example-block-navbar {
  background: #3ca99e !important;
}

.layout-example-block-navbar code {
  color: #fff;
}

.layout-example-block-content {
  background: #9e9e9e !important;
}

.layout-example-block-content code {
  color: #fff;
}

.layout-example-block-1 .layout-example-block-content {
  width: 74%;
}

.layout-example-block-2 .layout-example-block-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 74%;
}

.layout-example-block-2 > .layout-example-block {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.layout-example-block-2 .layout-example-block-content {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout-example-block-1-no-sidenav .layout-example-block-content {
  width: 100%;
}

.layout-example-code {
  display: block;
  overflow: auto;
  margin: 0;
  padding: 30px;
  min-width: 0;
  width: 100%;
  background: #fff;
}

.dark-style .layout-example-code {
  background: #2f3238;
}

.layout-example-run {
  display: inline-block;
  margin: 3px;
  padding: 4px 6px;
  border: 1px solid #eee;
  background: transparent;
  white-space: nowrap;
  line-height: 1;
}

.dark-style .layout-example-run {
  border-color: rgba(255, 255, 255, 0.2);
}

.layout-example-block-3 .layout-example-block-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-example-block-3 .layout-example-block-inner > .layout-example-block {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.layout-example-block-3 .layout-example-block-inner .layout-example-block-container-fluid {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout-example-block-3 .layout-example-block-sidenav-horizontal {
  background: #607d8b !important;
}

.layout-example-block-3 .layout-example-block-container-fluid {
  background: #fff !important;
}

.layout-example-block-3 .layout-example-block-container-fluid code {
  color: #666 !important;
}

.layout-example-block-3 .layout-example-block-footer {
  background: #e6514a !important;
}

.dark-style .layout-example-block-content .layout-example-block:not(.layout-example-block-sidenav-horizontal) code {
  color: #666 !important;
}

@media (max-width: 600px) {
  .modal-custom-lg .modal-dialog {
    max-width: 500px;
  }
  .modal-custom-lg .modal-content {
    max-width: 500px;
  }
}

@media (max-width: 900px) {
  .modal-custom-lg .modal-dialog {
    max-width: 800px;
  }
  .modal-custom-lg .modal-content {
    max-width: 800px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .modal-custom-lg .modal-dialog {
    max-width: 1100px;
  }
  .modal-custom-lg .modal-content {
    max-width: 1100px;
  }
}

@media (min-width: 1201px) and (max-width: 1800px) {
  .modal-custom-lg .modal-dialog {
    max-width: 1650px;
  }
  .modal-custom-lg .modal-content {
    max-width: 1650px;
  }
}

@media (min-width: 1801px) {
  .modal-custom-lg .modal-dialog {
    max-width: 1800px;
  }
  .modal-custom-lg .modal-content {
    max-width: 1800px;
  }
}

@media (min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

.dropdown-menu-right {
  right: auto !important;
}

.router-transitions {
  opacity: 1;
  transition: opacity .2s;
}

body.app-loading .router-transitions {
  opacity: .75;
}

@-webkit-keyframes ngTooltipAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes ngTooltipAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ngTooltipAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

ngb-tooltip-window {
  animation: ngTooltipAnimation .25s;
}

@-webkit-keyframes ngModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .5;
  }
}

@-moz-keyframes ngModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .5;
  }
}

@keyframes ngModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .5;
  }
}

@-webkit-keyframes ngMaterialModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .2;
  }
}

@-moz-keyframes ngMaterialModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .2;
  }
}

@keyframes ngMaterialModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .2;
  }
}

@-webkit-keyframes ngDefaultModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@-moz-keyframes ngDefaultModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes ngDefaultModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@-webkit-keyframes ngTopModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes ngTopModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ngTopModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes ngSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes ngSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ngSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes ngRtlSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes ngRtlSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ngRtlSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes ngFillInModalAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@-moz-keyframes ngFillInModalAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes ngFillInModalAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window.modal .modal-dialog {
  animation: ngDefaultModalAnimation .15s ease-out;
}

ngb-modal-window.modal-top .modal-dialog {
  animation: ngTopModalAnimation .15s ease-out;
}

ngb-modal-window.modal-slide .modal-dialog {
  animation: ngSlideModalAnimation .15s ease-out;
}

ngb-modal-window.modal-fill-in .modal-dialog {
  animation: ngFillInModalAnimation .15s ease-out;
}

[dir=rtl] ngb-modal-window.modal-slide .modal-dialog {
  animation: ngRtlSlideModalAnimation .15s ease-out;
}

.material-style ngb-modal-backdrop.modal-backdrop {
  opacity: .2;
  animation: ngMaterialModalBackdropAnimation .15s ease-out;
}

[dir=rtl] .dz-hidden-input {
  right: 0 !important;
  left: auto !important;
}

ngb-accordion .card-header > * > .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  vertical-align: inherit;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header > * > .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

body {
  overflow: hidden;
}

.button-txt {
  font-size: 11pt !important;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  color: white;
  padding: 5px 5px;
  font-weight: 500 !important;
}

a.non-pointer {
  cursor: text !important;
}

.searchResultItem {
  padding: 0px 0px 0px 10px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-size: 8pt;
  white-space: nowrap;
  cursor: pointer;
}

.searchResultItem:hover {
  color: black;
}

.sidenav.bg-white .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev,
.sidenav.bg-white .sidenav-horizontal-next {
  color: #4c4c4c !important;
}

.sidenav-link:hover {
  color: #eee !important;
}

.searchResultItem:focus {
  outline: 1px solid #60c0c7 !important;
}

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

.tabs-alt.nav-tabs .nav-link.active,
.tabs-alt.nav-tabs .nav-link.active:hover,
.tabs-alt.nav-tabs .nav-link.active:focus,
.tabs-alt > .nav-tabs .nav-link.active,
.tabs-alt > .nav-tabs .nav-link.active:hover,
.tabs-alt > .nav-tabs .nav-link.active:focus {
  -webkit-box-shadow: 0 -2px 0 #4cbae3 inset;
  box-shadow: 0 -2px 0 #4cbae3 inset !important;
  font-weight: bold;
  color: #4E5155 !important;
}

.table-nav.active:hover {
  color: #4e5155 !important;
  font-weight: bold !important;
}

.layout-navbar .navbar {
  border-bottom: 1px solid rgba(179, 179, 179, 0.39);
}

.nav-link:hover {
  color: #4E5155 !important;
}

.sort-arrow {
  float: right;
}

.sort-arrow:hover {
  cursor: pointer;
}

.tooltip {
  max-width: 1000px !important;
}

.tooltip-inner {
  max-width: 1000px !important;
}

.link-object {
  cursor: pointer;
}

.link-object:hover {
  border-bottom: 1px solid #6e7277;
  color: #6e7277 !important;
}

.tooltip.top {
  margin-bottom: 10px;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  margin-top: -5px;
}

.tooltip .show {
  z-index: 1 !important;
  position: absolute !important;
}

.divider {
  font-weight: 100px;
  font-size: 16pt;
  color: #eceded;
}

.quick-script:hover {
  border-bottom: 1px solid #1e70cd;
}

@media (min-height: 920px) {
  .grid-container {
    grid-template-columns: 1.5fr [Top-] 0.5fr 1fr 1fr;
    grid-template-rows: 0.6fr 1.2fr 1.2fr;
  }
  .top-chart-sizer {
    height: 275px;
  }
  .quadrant-chart {
    height: 350px;
  }
}

@media (max-height: 920px) {
  .grid-container {
    grid-template-columns: 1.5fr [Top-] 0.5fr 1fr 1fr;
    grid-template-rows: 0.2fr .9fr .9fr;
  }
  .quadrant-chart {
    height: 228px;
  }
  .top-chart-sizer {
    height: 200px;
  }
}

.info-box-value {
  font-weight: bold;
  padding-left: 5px;
}

.quadrant-chart {
  width: 100%;
}

.top-chart-sizer {
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-areas: "TopReport TopReport TopReport RightSide" "TopLeft TopRight TopRight RightSide" "BottomLeft BottomRight BottomRight RightSide" ". . . .";
  padding: 0px;
}

.RightSide {
  grid-area: RightSide;
}

.TopReport {
  grid-area: TopReport;
}

.TopRight {
  grid-area: TopRight;
}

.TopLeft {
  grid-area: TopLeft;
}

.BottomLeft {
  grid-area: BottomLeft;
}

.BottomRight {
  grid-area: BottomRight;
}

.chart-container {
  border: 1px solid #ccc;
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  padding: 25px;
}

#line-container {
  width: 100%;
  height: 100%;
}

.container-set {
  height: calc(100vh - 90px);
  overflow-y: auto;
  /* don't show content that exceeds my height */
  padding-left: 20px;
  padding-right: 20px;
}

.container-fluid {
  height: 100%;
  overflow-y: auto;
  /* don't show content that exceeds my height */
}

.RightDescription {
  padding: 25px;
  background-color: #eee;
  border: 1px solid #ded8d8;
  font-size: 11pt;
  height: 100%;
}

.CodeMirror-line {
  padding-left: 20px !important;
}

.table thead th {
  border-bottom: 2px solid #cdcdcf;
  font-weight: normal;
}

.node circle {
  fill: #fff;
  /*   stroke: steelblue; */
  stroke-width: 3px;
}

.node text {
  font-size: 12px;
  font-family: 'Alatsi', sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  /*   stroke-width: 2px; */
}

.btn-txt-bottom {
  width: 100%;
  text-align: center;
  color: #e6e6e6;
}

.btn-txt-bottom-overview {
  width: 100%;
  text-align: center;
  color: #a2a2a2;
}

.purple-txt-gradient {
  color: #814b82;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .purple-txt-gradient {
    background: -webkit-linear-gradient(#949494, #814b82);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.blue-txt-gradient {
  color: #814b82;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .blue-txt-gradient {
    background: -webkit-linear-gradient(#949494, #407184);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.red-txt-gradient {
  color: #dc0000;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .red-txt-gradient {
    background: -webkit-linear-gradient(#949494, #ce6f6f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.green-txt-gradient {
  color: #66ba89;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .green-txt-gradient {
    background: -webkit-linear-gradient(#949494, #66ba89);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.gold-txt-gradient {
  color: #ce7d00;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .gold-txt-gradient {
    background: -webkit-linear-gradient(#949494, #cca468);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.aqua-txt-gradient {
  color: #5ecbd4;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .aqua-txt-gradient {
    background: -webkit-linear-gradient(#949494, #5ecbd4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.black-txt-gradient {
  color: #5ecbd4;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .black-txt-gradient {
    background: -webkit-linear-gradient(#949494, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.blue-black-txt-gradient {
  color: #499b9e;
  padding-top: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .blue-black-txt-gradient {
    background: -webkit-linear-gradient(#2f3337, #499b9e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.sg-black-txt-gradient {
  color: #4caaca;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
  .sg-black-txt-gradient {
    background: -webkit-linear-gradient(#2f3337, #4caaca);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.sg-txt {
  color: #4cbae3;
}

.sg-txt-critical {
  color: #f15c5c;
}

div.tooltip {
  position: absolute;
  text-align: center;
  border: #5EBDB1 solid 2px;
  border-radius: 5px;
  padding: 3px;
  background: #A7DAC1;
  font-size: 12px;
  font-family: 'Alatsi', sans-serif;
}

.cube1,
.cube2,
.cube3,
.cube4,
.cube5,
.cube6,
.cube7,
.cube8,
.cube9 {
  background-color: #5dbdb9 !important;
}

.quick-script-btn {
  color: #2f2f2f;
  background: #f9f9f9;
  border: 1px solid #eceded;
}

.col-no-padding {
  padding: 0px !important;
}

.flex-container {
  display: flex;
}

.center-content {
  margin: 5px auto 0px;
  display: block;
  border-radius: 5em !important;
}

.info-btn-icon {
  width: calc(3rem + 2px);
  line-height: 2.8rem !important;
  border-radius: 5em !important;
}

.header-fixed > thead > tr > th {
  white-space: nowrap;
}

.header-fixed > thead > tr > th {
  width: 20%;
  float: left;
}

.no-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  overflow-y: scroll;
}

.no-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.clickable-row {
  cursor: pointer;
}

.CodeMirror {
  font-family: monospace;
  font-size: 10pt;
  height: 100%;
  padding-bottom: 25px;
  border-radius: 5px;
}

.CodeMirror-gutter {
  padding-right: 5px;
}

.cm-s-midnight .CodeMirror-gutters {
  background-color: #000000;
}

.cm-s-midnight.CodeMirror {
  background: #000000;
}

.perfect-height {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: calc(100vh - 245px);
  overflow-y: scroll;
}

.lineage-screen {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: calc(100vh - 420px);
  overflow-y: scroll;
}

.chart-div {
  height: calc(100vh - 330px);
  /** output container is small for display */
  width: calc(100vw - 330px);
  display: block;
}

.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dark-blue-dot {
  background-color: #02227e;
}

.blue-dot {
  background-color: #65abc5;
}

.red-dot {
  background-color: red;
}

.green-dot {
  background-color: green;
}

.yellow-dot {
  background-color: #b79c5f;
}

.gray-dot {
  background-color: #ccc;
}

.purple-dot {
  background-color: #8c7491;
}

.skin-dot {
  background-color: #bd7777;
}

.dark-blue-border {
  border-radius: 5px;
  border: 1px solid #02227e;
  color: #02227e;
}

.blue-border {
  border-radius: 5px;
  border: 1px solid #65abc5;
  color: #65abc5;
}

.red-border {
  border-radius: 5px;
  border: 1px solid red;
  color: red;
}

.yellow-border {
  color: #b79c5f;
  border-radius: 5px;
  border: 1px solid #b79c5f;
}

.green-border {
  border-radius: 5px;
  border: 1px solid green;
  color: green;
}

.gray-border {
  border-radius: 5px;
  border: 1px solid #ccc;
  color: #bbb;
}

.purple-border {
  border-radius: 5px;
  border: 1px solid #8c7491;
  color: #8c7491;
}

.CodeMirror-scroll {
  padding-top: 5px;
}

.sql-file {
  font-family: Courier;
  font-size: small;
  text-overflow: ellipsis;
  padding: 1px 5px;
  border-radius: 5px;
  display: inline-block;
  line-height: 1.5;
  cursor: pointer;
}

.sql-file:hover {
  font-weight: bold;
}

.version-popover-icon {
  cursor: pointer;
}

.version-popover-icon:hover {
  color: black;
}

.file-active {
  font-weight: 700;
  text-decoration: underline;
}

.accordion > .card > .show > .card-body {
  padding: 5px 5px 15px 15px;
}

.accordion > .card > .card-header {
  padding: 5px 5px 5px 15px;
}

.sticky-row {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  background-color: #eee;
  opacity: 0.2;
}

.editor-page-wrapper {
  height: calc(100vh - 184px);
}

.editor-control-bar {
  border-bottom: 1px solid #ddd;
  height: 65px;
}

.editor-control-button {
  background-color: #27b3ff;
  border-radius: 5rem !important;
  margin-right: 10px;
}

.editor-control-button:hover {
  background-color: #229ee0 !important;
}

.editor-control-button > span {
  color: white;
}

.editor-control-button-small {
  background-color: #27b3ff;
  border-radius: 3rem !important;
  margin-right: 10px;
}

.editor-control-button-small:hover {
  background-color: #229ee0 !important;
}

.editor-control-button-small > span {
  color: white;
}

.editor-control-button-normal {
  padding-top: .3rem !important;
  padding-bottom: .3rem !important;
}

.dark-mode {
  background-color: #2f3337 !important;
  color: #eee;
  border-color: #525252 !important;
}

.dark-mode > table > thead > tr > th {
  color: #eee;
}

.search-results-main {
  background-color: white;
}

.file-name-tab {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 2px 15px;
  background-color: white;
  font-weight: bold;
  line-height: 1.5;
  font-size: small;
  font-family: Courier;
  text-align: center;
  height: 30px;
}

.file-name-tab > .default {
  background-color: white;
}

.scheduler > cron-editor > ul > li {
  margin-right: 15px;
  padding: 10px 10px 3px 10px;
  font-size: 12pt;
}

.scheduler > cron-editor > ul > li.active {
  border-bottom: 2px solid #27b3ff;
  color: black;
}

.scheduler > cron-editor > ul > li > a {
  width: 100px;
}

.scheduler > cron-editor > ul > li:hover {
  color: black;
}

.scheduler > cron-editor > ul {
  padding-top: 15px;
  padding-left: 15px;
}

.scheduler > cron-editor > .cron-editor-container {
  padding-left: 30px;
  height: 175px;
}

.scheduler > cron-editor > .cron-editor-container > .row > .col-xs-12 > .tab-content > .tab-pane > .well {
  padding: 10px;
}

.editor-content {
  max-height: 90vh;
}

.btn-sg {
  background-color: #4cbae3;
  color: white;
}

.btn-sg:hover {
  background-color: #459fbd;
  color: white;
}

.btn-sg-border {
  border: 1px solid #4cbae3;
  color: #4cbae3;
  font-size: 10pt;
  padding: 7px 10px;
  border-radius: 7px;
}

.btn-sg-border:hover {
  border: 1px solid #459fbd;
  color: #459fbd;
  font-size: 10pt;
  padding: 7px 10px;
  border-radius: 7px;
}

.btn-sg-critical {
  background-color: #f15c5c;
}

.btn-sg-critical:hover {
  background-color: #f13b3b;
}

.btn-sg-test {
  border-color: #777777 !important;
  background: #ffffff !important;
  box-shadow: none !important;
  color: #5c5c5c !important;
}

.btn-sg-test:disabled {
  border-color: #777777 !important;
  background: #ffffff !important;
  box-shadow: none !important;
  color: #5c5c5c !important;
}

.btn-sg-test:hover {
  border-color: gray;
}

.editor-container {
  height: 100%;
  padding-left: 25px;
}

.editor-wrapper {
  border: 1px solid #eee;
  border-top-left-radius: 5px;
  height: 100%;
}

.control-bar-container {
  height: 100%;
  padding-left: 25px;
}

.control-bar-wrapper {
  border: 1px solid #eee;
  height: 100%;
}

.CodeMirror-linenumber {
  min-width: 35px;
}

.CodeMirror-gutters {
  border-right: 1px solid #f3f3f3;
  background-color: #fdfdfd;
  white-space: nowrap;
}

.file-control {
  padding: 7px;
  cursor: pointer;
  color: #949494;
}

.scheduler > cron-editor > .cron-editor-main > .cron-editor-container > .mat-tab-body-wrapper {
  padding: 0px 25px;
}

.mat-radio-label-content {
  padding-top: 10px;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #4a96b3;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #5eaf91;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5eaf91;
}

.mat-ripple-element {
  display: none !important;
}

as-split.as-transition.as-init:not(.as-dragging) ::ng-deep > .as-split-gutter,
as-split.as-transition.as-init:not(.as-dragging) > .as-split-area {
  transition: flex-basis 1.5s !important;
}

.collapse-query-nav {
  margin-right: 7px;
  cursor: pointer;
}

html:not([dir=rtl]) .ngx-datatable .datatable-header-cell:first-child,
html:not([dir=rtl]) .ngx-datatable .datatable-body-cell:first-child {
  border-left: none !important;
  color: #3e7a92;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row:last-child {
  border-bottom: 1px solid #d1d4d7 !important;
  z-index: 99;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-bottom: none !important;
}

.ngx-datatable .datatable-header-cell {
  background-color: white !important;
}

database-selection {
  border-bottom: 3px solid #d1d4d7 !important;
}

.query-results-title {
  border: 1px solid #eee;
  margin-bottom: 5px;
  padding: 5px 15px;
  border-top-left-radius: 5px;
  background-color: #f9f9f9;
  color: gray;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  padding: 3px 12px !important;
  color: #6b6b6b;
}

.datatable-row-wrapper {
  border-bottom: 2px solid #dddddd !important;
}

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  line-height: .75 !important;
}

.schedule-btn {
  background-color: #545454;
  color: white;
}

.schedule-btn:hover {
  background-color: #3a3a3a;
  color: white;
}

.schedule-btn-active {
  background-color: #4d8ba2 !important;
}

.extra-pad {
  padding-left: 1px !important;
}

.user-profile:hover {
  color: white !important;
}

:host ::ng-deep {
  /* Responsive */
}

:host ::ng-deep .p-paginator .p-paginator-current {
  margin-left: auto;
}

:host ::ng-deep .p-progressbar {
  height: .5rem;
  background-color: #D8DADC;
}

:host ::ng-deep .p-progressbar .p-progressbar-value {
  background-color: #607D8B;
}

:host ::ng-deep .table-header {
  display: flex;
  justify-content: space-between;
}

:host ::ng-deep .p-calendar .p-datepicker {
  min-width: 25rem;
}

:host ::ng-deep .p-calendar .p-datepicker td {
  font-weight: 400;
}

:host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

:host ::ng-deep .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}

:host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
  text-align: left;
}

:host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
  cursor: auto;
}

:host ::ng-deep .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

:host ::ng-deep .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 960px) {
  :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
  :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--layer-2);
  }
  :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }
  :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4rem 1rem -.4rem -.4rem;
    font-weight: bold;
  }
  :host ::ng-deep .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
    margin-top: .5rem;
  }
}

.card-info-stacked {
  float: left;
  padding: 0px 7px 10px;
  min-width: 5.4em !important;
  width: 33.33%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-3 {
  float: left;
  padding: 0px 7px 10px;
  min-width: 5.4em !important;
  width: 33.33%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-3 {
  float: left;
  padding: 0px 7px 10px;
  min-width: 5.4em !important;
  width: 33.33%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-4 {
  float: left;
  padding: 0px 7px 10px;
  min-width: 5em !important;
  width: 25%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-2 {
  float: left;
  padding: 0px 7px 10px;
  min-width: 6.2em !important;
  width: 50%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-1 {
  float: left;
  padding: 0px 7px 10px;
  min-width: 6.2em !important;
  width: 98%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-lg {
  float: left;
  padding: 0px 7px 10px;
  min-width: 6.2em !important;
  width: 33.33%;
}

.card-info-title {
  margin-left: 5px;
  vertical-align: top;
  line-height: 30px;
}

.card-subtitle-header {
  text-align: center;
  padding: 5px;
  padding-left: 5px;
  margin-top: 0.5rem !important;
}

.hover-over-btn {
  padding: 8px !important;
  border-radius: 5px;
  line-height: 1;
}

.hover-over-green {
  color: green;
}

.hover-over-green:hover {
  background-color: #b8e0b8;
  cursor: pointer;
  color: green;
}

.hover-over-gray {
  color: gray;
}

.hover-over-gray:hover {
  background-color: #f7f7f7;
  cursor: pointer;
  color: gray;
}

.hover-over-red {
  color: red;
}

.hover-over-red:hover {
  background-color: #ffe5e5;
  cursor: pointer;
  color: red;
}

.hover-over-purple {
  color: #6a0081;
}

.hover-over-purple:hover {
  background-color: #cbc7db;
  cursor: pointer;
  color: #6a0081;
}

.hover-over {
  cursor: pointer;
}

.p-inputswitch {
  width: 2.1rem !important;
  height: 1.2rem !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: .9rem !important;
  height: .9rem !important;
  left: 0.15rem !important;
  margin-top: -0.450rem !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: white !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(0.9rem) !important;
  transform: translateX(0.9rem) !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.6rem 0.9rem !important;
}

.pi {
  font-size: .7rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  height: 1.75rem !important;
}

.p-component {
  font-size: .8rem !important;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem .9rem !important;
}

.card-info-warning {
  color: red;
}

.run-now {
  width: 80px;
  height: 30px;
  padding: 2px !important;
  font-size: 10pt;
}

.overview-titles {
  padding: 13px 25px 10px 15px;
  font-size: 13pt;
}

.overview-card {
  padding: 0px;
}

.inline-edit {
  font-style: italic;
  font-size: 9pt;
  border: none !important;
  color: #477080;
  width: 100%;
}

.inline-edit input:focus {
  outline-offset: 0px !important;
  outline: none !important;
  border: none !important;
}

.shadow-hover {
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.11) !important;
  border-radius: 8px;
}

.sidenav-columns {
  width: 22rem;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 9999 !important;
  padding: 15px;
  border-top: 2px solid #eee;
  overflow-y: auto;
  background-color: #fdfdfd;
  font-size: 10pt;
  top: calc(65vh - 55px);
}

.main-sidenav-items {
  overflow-y: auto !important;
  overflow-x: hidden;
  height: calc(100vh - 230px);
  color: #6d6d6d !important;
  width: 22rem;
  margin-left: -16px;
  position: relative;
}

.main-sidenav-items-with-columns {
  overflow-y: auto !important;
  overflow-x: hidden;
  height: calc(65vh - 150px);
  color: #6d6d6d !important;
  width: 22rem;
  margin-left: -16px;
  position: relative;
}

.main-sidenav-items-inner {
  width: 19.5rem;
  margin-left: 16px;
}

.step-btn {
  width: 110px;
  border: 1px solid #dbdddd;
  border-radius: 5px;
  padding: 3px 13px;
}

.step-btn-lg {
  width: 150px !important;
}

.step-btn:hover {
  cursor: pointer;
}

.as-split-gutter {
  background-color: white !important;
  border: 1px solid #eee;
}

.nav-btns {
  background-size: 50px 50px !important;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  display: block;
}

.explore-btn {
  background: url(./assets/img/explore.png);
  margin-left: 13px;
  cursor: pointer;
}

.query-btn {
  background: url(./assets/img/query.png);
  margin-left: 15px;
  cursor: pointer;
}

.automate-btn {
  background: url(./assets/img/automate.png);
  margin-left: 15px;
  cursor: pointer;
}

.ng-carve .ng-select .ng-select-container {
  border: 1px solid black !important;
}

.clickable-row:hover {
  background-color: #fbfbfb !important;
  color: black !important;
}

.non-hover {
  background-color: inherit !important;
  font-weight: 500;
}

.non-hover:hover {
  background-color: inherit !important;
  cursor: text;
}

.profile-popover {
  width: 70vw;
  max-width: 1200px;
  height: 500px;
  overflow-y: scroll;
}

.toast-success {
  background-color: #4cbae3 !important;
}

.download-list {
  padding-top: 5px;
  cursor: pointer;
}

.download-list:hover {
  background-color: #eee;
  color: black !important;
}

.disabled-step {
  background-color: #ccc;
}

.job {
  border: 1px solid black;
  border-radius: 5px;
  width: 160px;
  height: 80px;
}

.step {
  border-radius: 5px;
  width: 160px;
  height: 90px;
  color: #4e5155;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  cursor: pointer;
}

.step-header {
  text-align: left;
  padding: 5px !important;
  font-size: 10pt;
}

.step-header-title {
  padding-left: 3px !important;
  width: 120px;
  overflow-y: hidden;
  height: 40px;
  font-weight: 500;
}

.step-body {
  padding: 10px !important;
  min-height: 40px !important;
}

.versions-popover {
  max-width: 430px !important;
  width: 430px !important;
}

.step-choices {
  font-size: 11pt;
  cursor: pointer;
  padding: 5px 8px;
  width: 100%;
}

.popover-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.step-choices:hover {
  background-color: #eee;
}

.stage {
  position: relative;
  margin-bottom: 1em;
  margin-top: 1.2em;
  width: 100%;
  padding: 30px;
}

.job-row-expanded {
  background-color: #2f3337 !important;
  color: white !important;
}

.highlighted-step {
  background: linear-gradient(to right, #a453c9 0%, #4a57cf 100%) !important;
  color: white !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border: 1px solid #4a57cf;
}

.running-highlighted-step {
  box-shadow: 2px 2px 1px #2196f3 !important;
}

.success-highlighted-step {
  box-shadow: 2px 2px 1px #228e21 !important;
}

.failed-highlighted-step {
  box-shadow: 2px 2px 1px #ff0204 !important;
}

.step-result-success-icon {
  color: #30816d !important;
}

.step-result-failed-icon {
  color: #ab2c26 !important;
}

.highlighted-row {
  border: 2px solid #a552c5 !important;
}

.code-file {
  color: #2196f3;
}

.code-file-highlight {
  color: white;
}

.CodeMirror pre {
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.bouncy-ball {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 10px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #26B4FF !important;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.disabled-file {
  cursor: not-allowed;
}

.double-bounce-spinner {
  position: relative;
  margin: 0px !important;
  width: 25px !important;
  height: 25px !important;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.query-result-details {
  font-weight: bold;
  text-align: left;
  width: 100px;
  padding-right: 10px;
  color: #ccc;
  padding-top: 10px;
  display: inline-block;
}

.form-group {
  margin-bottom: 1.5rem;
}

.error {
  color: red;
}

.form-border {
  border: 1px solid #0a0d10;
}

.pulse {
  background: black;
  color: white;
  box-shadow: 0 0 0 0 black;
  transform: scale(1);
  animation: pulse 2s infinite;
}

.pulse.red {
  background: #ff5252;
  box-shadow: 0 0 0 0 #ff5252;
  animation: pulse-red 2s infinite;
}

.pulse.blue {
  background: #03bdbf;
  box-shadow: 0 0 0 0 #0ea0a2;
  animation: pulse-blue 2s infinite;
}

.navbar-item-active {
  font-weight: bold;
  font-size: 16px;
}

.setup-btn {
  width: 200px;
}

.green-plus {
  cursor: pointer;
  color: green;
  padding-top: 7px;
}

.green-plus:hover {
  cursor: pointer;
  color: #006400;
}

.nav-link.active:hover {
  color: white !important;
}

.red-minus {
  cursor: pointer;
  color: red;
  padding-top: 7px;
}

.red-minus:hover {
  color: #c40000;
}

.email-sent {
  font-size: 12pt;
  margin-top: 40px;
  text-align: left;
  border-radius: 10px;
}

.main-btn {
  width: 200px;
}

.step-card {
  box-shadow: 0 1px 10px #00000040;
}

.job-open-body {
  box-shadow: 0px 4px 10px #00000040 !important;
}

.job-open-header {
  box-shadow: 0px -2px 5px #00000040 !important;
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.5);
}

.popover {
  box-shadow: 0px 1px 10px 0px #00000040 !important;
}

.dropdown-menu {
  box-shadow: 0 1px 10px #00000040 !important;
}

.overview-icon {
  vertical-align: bottom;
  padding-bottom: 6px;
  font-size: 1.1rem;
}

.sidenav-vertical .sidenav-menu .sidenav-link {
  padding-left: 1.8rem;
}

.mx-2 {
  margin-left: 0rem !important;
}

@media (min-width: 992px) {
  .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav .sidenav-vertical,
  .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical {
    width: 2.8rem !important;
  }
}

.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 3.5rem;
}

.sidenav-vertical,
.sidenav-vertical .sidenav-block,
.sidenav-vertical .sidenav-inner > .sidenav-item,
.sidenav-vertical .sidenav-inner > .sidenav-header {
  width: 22rem !important;
}

.sidenav-vertical .sidenav-icon {
  width: 0.6rem;
}

.column-hover:hover {
  cursor: pointer;
  background-color: #eee;
  border-radius: 15px;
  border: none;
}

.step-pill {
  background-color: #339FEE;
  color: white;
  border-radius: 50px;
  padding: 5px;
  height: 30px;
  width: 40px;
  text-align: center;
}

.integration-btn {
  font-size: 14pt;
  border-radius: 15px;
  border: 1px solid #4f5155;
  padding: 10px;
  width: 180px;
  height: 90px;
}

.integration-btn:hover {
  box-shadow: 0px 1px 2px 0px #2ea0c9;
}

.spinner-border-delete:hover {
  color: white !important;
}

.integration-btn-connected {
  font-size: 14pt;
  border-radius: 15px;
  border: 1px solid #4f5155;
  padding: 25px;
  width: 250px;
  height: 125px;
}

.integration-btn-connected:hover {
  box-shadow: 0px 1px 2px 0px #7b7b7b;
}

.integration-title {
  font-size: 11pt;
  width: 100%;
  overflow-wrap: anywhere;
  padding-top: 15px;
  line-height: 1rem;
  font-weight: bold;
  color: black;
}

.package-option-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 24px;
  height: 150px;
}

.package-option-box:hover {
  box-shadow: 0px 1px 2px 0px #7b7b7b;
  cursor: pointer;
}

.package-option-selected {
  box-shadow: 0px 1px 2px 0px #2ea0c9;
}

.package-option-title {
  font-size: 12pt;
  font-weight: bold;
  padding-bottom: 5px;
}

.package-option-description {
  font-size: 10pt;
  height: 75px;
  overflow: hidden;
  width: 90%;
}

.package-option-selected-icon {
  color: #2ea0c9;
  text-align: right;
}

.package-option-icon {
  text-align: right;
}

.input-error {
  border: 1px solid red !important;
}

.package-profile-box {
  height: 110px;
  width: 220px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #7b9175;
}

.package-profile-box:hover {
  box-shadow: 0px 1px 2px 0px #7b9175;
  cursor: pointer;
}

.package-profile-title {
  padding-top: 25px;
  font-size: 13pt;
  font-weight: bold;
  padding-bottom: 5px;
  color: #7b9175;
}

.package-profile-description {
  font-size: 10pt;
}

.profile-secure-field {
  cursor: pointer;
  padding: 8px 10px;
  text-align: center;
  border-radius: 5px;
  width: 50px;
}

.automate-job-nav {
  cursor: pointer;
  margin-right: 15px;
  display: inline-block;
}

.automate-top-nav {
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
  border-right: 1px solid #ccc;
}

.variable-pills {
  margin: 5px 5px 5px 0px;
  font-size: small;
  padding: 7px 10px;
  border-radius: 5px;
}

.carve-scrollable-container {
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: auto;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 160, 162, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(14, 160, 162, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 160, 162, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
