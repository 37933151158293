// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import '~primeng/resources/themes/saga-blue/theme.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-toastr/toastr';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$snowguide-primary: mat-palette($mat-indigo);
$snowguide-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$snowguide-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$snowguide-theme: mat-light-theme($snowguide-primary, $snowguide-accent, $snowguide-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($snowguide-theme);


@import './demo';

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

// @mixin modal-fullscreen() {
//   padding: 0 !important; // override inline padding-right added from js

//   .modal-dialog {
//     width: 100%;
//     max-width: none;
//     height: 100%;
//     margin: 0;
//   }

//   .modal-content {
//     height: 100%;
//     border: 0;
//     border-radius: 0;
//   }

//   .modal-body {
//     overflow-y: auto;
//   }

// }

// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-down($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//     .modal-fullscreen#{$infix} {
//       @include modal-fullscreen();
//     }

//   }
// }
@media (max-width: 600px) {

  .modal-custom-lg .modal-dialog {
    max-width: 500px;
  }

  .modal-custom-lg .modal-content {
    max-width: 500px;
  }
}

@media (max-width: 900px) {

  .modal-custom-lg .modal-dialog {
    max-width: 800px;
  }

  .modal-custom-lg .modal-content {
    max-width: 800px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {

  .modal-custom-lg .modal-dialog {
    max-width: 1100px;
  }

  .modal-custom-lg .modal-content {
    max-width: 1100px;
  }
}

@media (min-width: 1201px) and (max-width: 1800px) {

  .modal-custom-lg .modal-dialog {
    max-width: 1650px;
  }

  .modal-custom-lg .modal-content {
    max-width: 1650px;
  }
}

@media (min-width: 1801px) {

  .modal-custom-lg .modal-dialog {
    max-width: 1800px;
  }

  .modal-custom-lg .modal-content {
    max-width: 1800px;
  }
}







// Remove outline on desktops
@media(min-width: 720px) {

  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

.dropdown-menu-right {
  right: auto !important;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Tooltip and popover animations

@include keyframes(ngTooltipAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

ngb-tooltip-window {
  animation: ngTooltipAnimation .25s;
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .5;
  }
}

@include keyframes(ngMaterialModalBackdropAnimation) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .2;
  }
}

@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(ngRtlSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .15s ease-out;
  }

  [dir=rtl] &.modal-slide .modal-dialog {
    animation: ngRtlSlideModalAnimation .15s ease-out;
  }
}

.material-style ngb-modal-backdrop.modal-backdrop {
  opacity: .2;
  animation: ngMaterialModalBackdropAnimation .15s ease-out;
}

// Prevent horizontal scrollbar
[dir=rtl] .dz-hidden-input {
  right: 0 !important;
  left: auto !important;
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header>*>.btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  vertical-align: inherit;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header>*>.btn.btn-link {
  display: flex;
  justify-content: space-between;
}

body {
  overflow: hidden;
}

.button-txt {
  font-size: 11pt !important;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  color: white;
  padding: 5px 5px;
  font-weight: 500 !important;
}

a.non-pointer {
  cursor: text !important;
}

.searchResultItem {
  padding: 0px 0px 0px 10px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-size: 8pt;
  white-space: nowrap;
  cursor: pointer;
}

.searchResultItem:hover {
  color: black;
}

.sidenav.bg-white .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev,
.sidenav.bg-white .sidenav-horizontal-next {
  color: #4c4c4c !important;
}

.sidenav-link:hover {
  color: #eee !important;
}

// .btn-lg, .btn-group-lg>.btn{
//   border-radius: 5em !important;
// }

.searchResultItem:focus {
  outline: 1px solid #60c0c7 !important;
}

// .button-txt:hover{
//   border-style: 1px solid gray;
//   border-radius: 10px;
//   background-color: gray;
//   color:white;
// }
// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

.tabs-alt.nav-tabs .nav-link.active,
.tabs-alt.nav-tabs .nav-link.active:hover,
.tabs-alt.nav-tabs .nav-link.active:focus,
.tabs-alt>.nav-tabs .nav-link.active,
.tabs-alt>.nav-tabs .nav-link.active:hover,
.tabs-alt>.nav-tabs .nav-link.active:focus {
  -webkit-box-shadow: 0 -2px 0 #4cbae3 inset;
  box-shadow: 0 -2px 0 #4cbae3 inset !important;
  font-weight: bold;
  color: #4E5155 !important;
  //background-color: 
  // border-top-left-radius: 10px !important;
  // border-top-right-radius: 10px !important;
  // border-radius-top-left: 5px !important;
  // border: 1px solid #5ebfc5 !important;
  //font-size: 14pt;
}

.table-nav {}

.table-nav.active:hover {
  color: #4e5155 !important;
  ;
  font-weight: bold !important;
}

.layout-navbar .navbar {
  border-bottom: 1px solid rgba(179, 179, 179, 0.39);
}

.nav-link:hover {
  color: #4E5155 !important;
  //font-weight: bold !important;
}

.sort-arrow {
  float: right;
}

.sort-arrow:hover {
  cursor: pointer;
}

.tooltip {
  max-width: 1000px !important;
  // margin-left: 5px;
}

.tooltip-inner {
  max-width: 1000px !important;
  // background-color: gray;
}

.link-object {
  cursor: pointer;
}

.link-object:hover {
  border-bottom: 1px solid #6e7277;
  color: #6e7277 !important;
}

.tooltip.top {
  margin-bottom: 10px;
}

// .bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
//     border-bottom-color: #4E5155 !important;
// }
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  margin-top: -5px;
}

// .bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
//     border-left-color: gray !important;
// }
// .bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
//     border-right-color: gray !important;
// }

.tooltip .show {
  z-index: 1 !important;
  position: absolute !important;
  //max-width: 300px;
}

.divider {
  //padding: 5px;
  font-weight: 100px;
  font-size: 16pt;
  color: #eceded;
}

.quick-script:hover {
  border-bottom: 1px solid #1e70cd;
}

@media(min-height: 920px) {
  .grid-container {
    grid-template-columns: 1.5fr [Top-] 0.5fr 1fr 1fr;
    grid-template-rows: 0.6fr 1.2fr 1.2fr;
  }

  .top-chart-sizer {
    height: 275px;
  }

  .quadrant-chart {
    height: 350px;
  }
}

@media(max-height: 920px) {
  .grid-container {
    grid-template-columns: 1.5fr [Top-] 0.5fr 1fr 1fr;
    grid-template-rows: 0.2fr .9fr .9fr;
  }

  .quadrant-chart {
    height: 228px;
  }

  .top-chart-sizer {
    height: 200px;
  }

}

.info-box-value {
  font-weight: bold;
  padding-left: 5px;
}

.quadrant-chart {
  width: 100%;
}

.top-chart-sizer {
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-areas: "TopReport TopReport TopReport RightSide" "TopLeft TopRight TopRight RightSide" "BottomLeft BottomRight BottomRight RightSide" ". . . .";
  padding: 0px;
}


.RightSide {
  grid-area: RightSide;
}

.TopReport {
  grid-area: TopReport;
}

.TopRight {
  grid-area: TopRight;
}

.TopLeft {
  grid-area: TopLeft;
}

.BottomLeft {
  grid-area: BottomLeft;
}

.BottomRight {
  grid-area: BottomRight;
}

.chart-container {
  border: 1px solid #ccc;
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  padding: 25px;
}

#line-container {
  width: 100%;
  height: 100%;
}

.container-set {
  height: calc(100vh - 90px);
  overflow-y: auto;
  /* don't show content that exceeds my height */
  padding-left: 20px;
  padding-right: 20px;
}

.container-fluid {
  height: 100%;
  overflow-y: auto;
  /* don't show content that exceeds my height */
}


// .layout-container{
//   /*height: calc(100vh)*/
//   overflow-y: scroll;
// }

.RightDescription {
  padding: 25px;
  background-color: #eee;
  border: 1px solid #ded8d8;
  font-size: 11pt;
  height: 100%;
}



.CodeMirror-line {
  padding-left: 20px !important
}

.table thead th {
  border-bottom: 2px solid #cdcdcf;
  font-weight: normal;
  ;
}


.node circle {
  fill: #fff;
  /*   stroke: steelblue; */
  stroke-width: 3px;
}

.node text {
  font-size: 12px;
  font-family: 'Alatsi', sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  /*   stroke-width: 2px; */
}

.quickscript-dropdown {}

.btn-txt-bottom {
  width: 100%;
  text-align: center;
  color: #e6e6e6;
}

.btn-txt-bottom-overview {
  width: 100%;
  text-align: center;
  color: #a2a2a2;
}

.purple-txt-gradient {
  color: #814b82;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#949494, #814b82);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

.blue-txt-gradient {
  color: #814b82;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#949494, #407184);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.red-txt-gradient {
  color: #dc0000;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#949494, #ce6f6f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.green-txt-gradient {
  color: #66ba89;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#949494, #66ba89);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.gold-txt-gradient {
  color: #ce7d00;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#949494, #cca468);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

.aqua-txt-gradient {
  color: #5ecbd4;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#949494, #5ecbd4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.black-txt-gradient {
  color: #5ecbd4;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#949494, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.blue-black-txt-gradient {
  color: #499b9e;
  padding-top: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#2f3337, #499b9e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.sg-black-txt-gradient {
  color: #4caaca;
  padding-top: 0.2em;
  padding-bottom: 0.2em;

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background: -webkit-linear-gradient(#2f3337, #4caaca);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.sg-txt {
  color: #4cbae3;
}

.sg-txt-critical {
  color: #f15c5c;
}

div.tooltip {
  position: absolute;
  text-align: center;
  border: #5EBDB1 solid 2px;
  border-radius: 5px;
  padding: 3px;
  background: #A7DAC1;
  font-size: 12px;
  font-family: 'Alatsi', sans-serif;
}

.cube1,
.cube2,
.cube3,
.cube4,
.cube5,
.cube6,
.cube7,
.cube8,
.cube9 {
  background-color: #5dbdb9 !important;
}

.quick-script-btn {
  color: #2f2f2f;
  background: #f9f9f9;
  border: 1px solid #eceded;
}

.col-no-padding {
  padding: 0px !important;
}

.flex-container {
  display: flex;
}

.center-content {
  margin: 5px auto 0px;
  display: block;
  border-radius: 5em !important;
}

.info-btn-icon {
  width: calc(3rem + 2px);
  line-height: 2.8rem !important;
  border-radius: 5em !important;
}

.header-fixed>thead>tr>th {
  white-space: nowrap;
}

.header-fixed>thead>tr>th {
  width: 20%;
  float: left;
}

.no-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  overflow-y: scroll;
}

.no-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.clickable-row {
  cursor: pointer;
}

.CodeMirror {
  font-family: monospace;
  font-size: 10pt;
  height: 100%;
  padding-bottom: 25px;
  border-radius: 5px;
}

.CodeMirror-gutter {
  // background-color: #f9f9f9;
  padding-right: 5px;
  // background-color: rgb(251 251 251);
  // border-right: 1px solid #f3f3f3;
}

.cm-s-midnight .CodeMirror-gutters {
  background-color: #000000;
}


.cm-s-midnight.CodeMirror {
  background: #000000;
}

.perfect-height {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: calc(100vh - 245px);
  overflow-y: scroll;
}


.lineage-screen {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: calc(100vh - 420px);
  overflow-y: scroll;
}

.chart-div {
  height: calc(100vh - 330px);
  /** output container is small for display */
  width: calc(100vw - 330px);
  display: block;
}

.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dark-blue-dot {
  background-color: #02227e;
}

.blue-dot {
  background-color: #65abc5;
}

.red-dot {
  background-color: red;
}

.green-dot {
  background-color: green;
}

.yellow-dot {
  background-color: #b79c5f;
}

.gray-dot {
  background-color: #ccc;
}

.purple-dot {
  background-color: #8c7491;
}

.skin-dot {
  background-color: #bd7777;
}

.dark-blue-border {
  border-radius: 5px;
  border: 1px solid #02227e;
  color: #02227e;
}

.blue-border {
  border-radius: 5px;
  border: 1px solid #65abc5;
  color: #65abc5;
}

.red-border {
  border-radius: 5px;
  border: 1px solid red;
  color: red;
}

.yellow-border {
  color: #b79c5f;
  border-radius: 5px;
  border: 1px solid #b79c5f;
}

.green-border {
  border-radius: 5px;
  border: 1px solid green;
  color: green;
}

.gray-border {
  border-radius: 5px;
  border: 1px solid #ccc;
  color: #bbb;
}

.purple-border {
  border-radius: 5px;
  border: 1px solid #8c7491;
  color: #8c7491
}



.CodeMirror-scroll {
  padding-top: 5px;
}

.sql-file {
  font-family: Courier;
  font-size: small;
  text-overflow: ellipsis;
  padding: 1px 5px;
  border-radius: 5px;
  display: inline-block;
  line-height: 1.5;
  cursor: pointer;
}

.sql-file:hover {
  font-weight: bold;
}

.version-popover-icon {
  cursor: pointer;
}

.version-popover-icon:hover {
  color: black;
}


.file-active {
  font-weight: 700;
  text-decoration: underline;
}

.accordion>.card>.show>.card-body {
  padding: 5px 5px 15px 15px;
}

.accordion>.card>.card-header {
  padding: 5px 5px 5px 15px;
}

// .submenu-custom > .sidenav-icon{
//   margin-right: .25rem !important;
// }
.sticky-row {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  background-color: #eee;
  opacity: 0.2;

}

.editor-page-wrapper {
  height: calc(100vh - 184px)
}

.editor-control-bar {
  border-bottom: 1px solid #ddd;
  height: 65px;
}

.editor-control-button {
  background-color: #27b3ff;
  border-radius: 5rem !important;
  margin-right: 10px;
}

.editor-control-button:hover {
  background-color: #229ee0 !important;
}

.editor-control-button>span {
  color: white;
}

.editor-control-button-small {
  background-color: #27b3ff;
  border-radius: 3rem !important;
  margin-right: 10px;
}

.editor-control-button-small:hover {
  background-color: #229ee0 !important;
}

.editor-control-button-small>span {
  color: white;
}

.editor-control-button-normal {
  padding-top: .3rem !important;
  padding-bottom: .3rem !important;
}

.dark-mode {
  background-color: #2f3337 !important;
  color: #eee;
  border-color: #525252 !important;
}

.dark-mode>table>thead>tr>th {
  color: #eee;
}

.search-results-main {
  background-color: white;
}

.file-name-tab {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 2px 15px;
  background-color: white;
  font-weight: bold;
  line-height: 1.5;
  font-size: small;
  font-family: Courier;
  text-align: center;
  height: 30px;
}

.file-name-tab>.default {
  background-color: white;
}


.scheduler>cron-editor>ul>li {
  margin-right: 15px;
  padding: 10px 10px 3px 10px;
  font-size: 12pt;
}

.scheduler>cron-editor>ul>li.active {
  border-bottom: 2px solid #27b3ff;
  color: black;
}

.scheduler>cron-editor>ul>li>a {
  width: 100px;
}

.scheduler>cron-editor>ul>li:hover {
  color: black;
}

.scheduler>cron-editor>ul {
  padding-top: 15px;
  padding-left: 15px;
}

.scheduler>cron-editor>.cron-editor-container {
  padding-left: 30px;
  height: 175px;
}

.scheduler>cron-editor>.cron-editor-container>.row>.col-xs-12>.tab-content>.tab-pane>.well {
  padding: 10px;
}

.table-responsive {
  // height:400px;  
  // overflow:none;
}

.editor-content {
  max-height: 90vh;
}

.btn-sg {
  background-color: #4cbae3;
  color: white;
}

.btn-sg:hover {
  background-color: #459fbd;
  color: white;
}

.btn-sg-border {
  border: 1px solid #4cbae3;
  color: #4cbae3;
  font-size: 10pt;
  padding: 7px 10px;
  border-radius: 7px;
}

.btn-sg-border:hover {
  border: 1px solid #459fbd;
  color: #459fbd;
  font-size: 10pt;
  padding: 7px 10px;
  border-radius: 7px;
}

.btn-sg-critical {
  background-color: #f15c5c;
}

.btn-sg-critical:hover {
  background-color: #f13b3b;
}

.btn-sg-test {
  border-color: #777777 !important;
  background: #ffffff !important;
  box-shadow: none !important;
  color: #5c5c5c !important;
}

.btn-sg-test:disabled {
  border-color: #777777 !important;
  background: #ffffff !important;
  box-shadow: none !important;
  color: #5c5c5c !important;
}

.btn-sg-test:hover {
  border-color: gray;
}

.editor-container {
  height: 100%;
  padding-left: 25px;
}

.editor-wrapper {
  border: 1px solid #eee;
  border-top-left-radius: 5px;
  height: 100%;
}

.control-bar-container {
  height: 100%;
  padding-left: 25px;
}

.control-bar-wrapper {
  border: 1px solid #eee;
  height: 100%;
}

.CodeMirror-linenumber {
  min-width: 35px;
}

.CodeMirror-gutters {
  border-right: 1px solid #f3f3f3;
  background-color: #fdfdfd;
  white-space: nowrap;
}

.file-control {
  padding: 7px;
  cursor: pointer;
  color: #949494;
}

.scheduler>cron-editor>.cron-editor-main>.cron-editor-container>.mat-tab-body-wrapper {
  padding: 0px 25px;
}

.mat-radio-label-content {
  padding-top: 10px;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  // z-index: 99999 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #4a96b3;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #5eaf91;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5eaf91;
}


.mat-ripple-element {
  display: none !important;
}

// .table-responsive > table > thead tr:nth-child(1) th{
//     border-top: 1px solid #eee;
//     background-color: #e4e5e5;
//     position: sticky;
//     top: 0;
//     z-index: 10;
//   }


as-split.as-transition.as-init:not(.as-dragging) ::ng-deep>.as-split-gutter,
as-split.as-transition.as-init:not(.as-dragging)>.as-split-area {
  transition: flex-basis 1.5s !important;
}

.collapse-query-nav {
  margin-right: 7px;
  cursor: pointer;
}

html:not([dir=rtl]) .ngx-datatable .datatable-header-cell:first-child,
html:not([dir=rtl]) .ngx-datatable .datatable-body-cell:first-child {
  border-left: none !important;
  color: #3e7a92;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row:last-child {
  border-bottom: 1px solid #d1d4d7 !important;
  z-index: 99;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-bottom: none !important;
}

.ngx-datatable .datatable-header-cell {
  background-color: white !important;
}

database-selection {
  border-bottom: 3px solid #d1d4d7 !important;
}

.query-results-title {
  border: 1px solid #eee;
  margin-bottom: 5px;
  padding: 5px 15px;
  border-top-left-radius: 5px;
  background-color: #f9f9f9;
  color: gray;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  padding: 3px 12px !important;
  color: #6b6b6b;
}

.datatable-row-wrapper {
  border-bottom: 2px solid #dddddd !important;
}

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  line-height: .75 !important;
}

.schedule-btn {
  background-color: #545454;
  color: white;
}

.schedule-btn:hover {
  background-color: #3a3a3a;
  color: white;
}

.schedule-btn-active {
  background-color: #4d8ba2 !important;
}

.extra-pad {
  padding-left: 1px !important;
}

.user-profile:hover {
  color: white !important;
}

:host ::ng-deep {
  .p-paginator {
    .p-paginator-current {
      margin-left: auto;
    }
  }

  .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
      background-color: #607D8B;
    }
  }

  .table-header {
    display: flex;
    justify-content: space-between;
  }

  .p-calendar .p-datepicker {
    min-width: 25rem;

    td {
      font-weight: 400;
    }
  }

  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
    }

    .p-paginator {
      padding: 1rem;
    }

    .p-datatable-thead>tr>th {
      text-align: left;
    }

    .p-datatable-tbody>tr>td {
      cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }

  /* Responsive */
  .p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  :host ::ng-deep {
    .p-datatable {
      &.p-datatable-customers {

        .p-datatable-thead>tr>th,
        .p-datatable-tfoot>tr>td {
          display: none !important;
        }

        .p-datatable-tbody>tr {
          border-bottom: 1px solid var(--layer-2);

          >td {
            text-align: left;
            display: block;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: .4rem;
              min-width: 30%;
              display: inline-block;
              margin: -.4rem 1rem -.4rem -.4rem;
              font-weight: bold;
            }

            .p-progressbar {
              margin-top: .5rem;
            }
          }
        }
      }
    }
  }

}

.card-info-stacked {
  float: left;
  padding: 0px 7px 10px;
  min-width: 5.4em !important;
  width: 33.33%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-3{
  float: left; 
  padding: 0px 7px 10px;
  min-width: 5.4em !important;
  width:33.33%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-3{
  float: left; 
  padding: 0px 7px 10px;
  min-width: 5.4em !important;
  width:33.33%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-4{
  float: left; 
  padding: 0px 7px 10px;
  min-width: 5em !important;
  width: 25%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-2 {
  float: left;
  padding: 0px 7px 10px;
  min-width: 6.2em !important;
  width: 50%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-1 {
  float: left;
  padding: 0px 7px 10px;
  min-width: 6.2em !important;
  width: 98%;
  text-align: center;
  font-size: 10pt;
}

.card-info-stacked-lg {
  float: left;
  padding: 0px 7px 10px;
  min-width: 6.2em !important;
  width: 33.33%;
}

.card-info-title {
  margin-left: 5px;
  vertical-align: top;
  line-height: 30px;
}

.card-subtitle-header {
  text-align: center;
  padding: 5px;
  padding-left: 5px;
  margin-top: 0.5rem !important;
}

.hover-over-btn {
  padding: 8px !important;
  border-radius: 5px;
  line-height: 1;
}

.hover-over-green {
  color: green;
}

.hover-over-green:hover {
  background-color: #b8e0b8;
  cursor: pointer;
  color: green;
}

.hover-over-gray {
  color: gray;
}

.hover-over-gray:hover {
  background-color: #f7f7f7;
  cursor: pointer;
  color: gray;
}

.hover-over-red {
  color: red;
}

.hover-over-red:hover {
  background-color: #ffe5e5;
  cursor: pointer;
  color: red;
}

.hover-over-purple {
  color: #6a0081;
}

.hover-over-purple:hover {
  background-color: #cbc7db;
  cursor: pointer;
  color: #6a0081;
}

.hover-over{
  cursor: pointer;
}


.p-inputswitch {
  width: 2.1rem !important;
  height: 1.2rem !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: .9rem !important;
  height: .9rem !important;
  left: 0.15rem !important;
  margin-top: -0.450rem !important;
}

.p-datatable .p-datatable-thead>tr>th {
  background-color: white !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(.90rem) !important;
  transform: translateX(.90rem) !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
  padding: 0.6rem 0.9rem !important;
}

.pi {
  font-size: .7rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  height: 1.75rem !important;
}

.p-component {
  font-size: .8rem !important;
}

.p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
  padding: 0.5rem .9rem !important;
}

.card-info-warning {
  color: red;
}

.run-now {
  width: 80px;
  height: 30px;
  padding: 2px !important;
  font-size: 10pt;
}

.overview-titles {
  padding: 13px 25px 10px 15px;
  font-size: 13pt;
  // font-weight: 500;
}

.overview-card {
  padding: 0px;
}

.inline-edit {
  font-style: italic;
  font-size: 9pt;
  border: none !important;
  color: #477080;
  width: 100%;
}

.inline-edit input:focus {
  outline-offset: 0px !important;
  outline: none !important;
  border: none !important;
}


.shadow-hover {
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.11) !important;
  border-radius: 8px;
}

.sidenav-columns {
  width: 22rem;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 9999 !important;
  padding: 15px;
  border-top: 2px solid #eee;
  overflow-y: auto;
  background-color: #fdfdfd;
  font-size: 10pt;
  top: calc(65vh - 55px);

  // color: #497282;
}

.main-sidenav-items {
  overflow-y: auto !important;
  overflow-x: hidden;
  height: calc(100vh - 230px);
  color: #6d6d6d !important;
  width: 22rem;
  margin-left: -16px;
  position: relative;

}

.main-sidenav-items-with-columns {
  overflow-y: auto !important;
  overflow-x: hidden;
  height: calc(65vh - 150px);
  color: #6d6d6d !important;
  width: 22rem;
  margin-left: -16px;
  position: relative;
  // border-bottom: 3px solid #ececec;
}

.main-sidenav-items-inner {
  width: 19.5rem;
  margin-left: 16px;
}

.step-btn {
  width: 110px;
  border: 1px solid #dbdddd;
  border-radius: 5px;
  padding: 3px 13px;
}

.step-btn-lg {
  width: 150px !important;
}

.step-btn:hover {
  cursor: pointer;
}

.as-split-gutter {
  background-color: white !important;
  border: 1px solid #eee;

}

.nav-btns {
  background-size: 50px 50px !important;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  display: block;

}

.explore-btn {
  background: url(./assets/img/explore.png);
  margin-left: 13px;
  cursor: pointer;
}

// .explore-btn:hover{
//   cursor: pointer;
//   background: url(./assets/img/explore_hover.png);
// }

.query-btn {
  background: url(./assets/img/query.png);
  margin-left: 15px;
  cursor: pointer;
}

// .query-btn:hover{
//   cursor: pointer;
//   background: url(./assets/img/query_hover.png);
// }

.automate-btn {
  background: url(./assets/img/automate.png);
  margin-left: 15px;
  cursor: pointer;

}

.ng-carve .ng-select .ng-select-container {
  border: 1px solid black !important;
}

// .automate-btn:hover{
//   cursor: pointer;
//   background: url(./assets/img/automate_hover.png);
// }

.clickable-row:hover {
  background-color: #fbfbfb !important;
  color: black !important;
}

.non-hover {
  background-color: inherit !important;
  font-weight: 500;

}

.non-hover:hover {
  background-color: inherit !important;
  cursor: text;
}

.profile-popover {
  width: 70vw;
  max-width: 1200px;
  height: 500px;
  overflow-y: scroll;
}

.toast-success {
  background-color: #4cbae3 !important;
}

.download-list {
  padding-top: 5px;
  cursor: pointer;
}

.download-list:hover {
  background-color: #eee;
  color: black !important;
}

.disabled-step {
  background-color: #ccc;
}

.job {
  border: 1px solid black;
  border-radius: 5px;
  // position: absolute;
  width: 160px;
  height: 80px;
}

.step {
  // border: 1px solid black;
  border-radius: 5px;

  // position: absolute;
  width: 160px;
  height: 90px;
  color: #4e5155;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  // /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#8323af+0,283ae0+100 */
  // background: rgb(131,35,175); /* Old browsers */
  // background: -moz-linear-gradient(left,  rgba(131,35,175,1) 0%, rgba(40,58,224,1) 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(left,  rgba(131,35,175,1) 0%,rgba(40,58,224,1) 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(to right, rgb(164, 83, 201) 0%, rgb(74, 87, 207) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8323af', endColorstr='#283ae0',GradientType=1 ); /* IE6-9 */
  // color: white;
  cursor: pointer;

}

.step-header {
  text-align: left;
  padding: 5px !important;
  font-size: 10pt;
}

.step-header-title {
  padding-left: 3px !important;
  width: 120px;
  overflow-y: hidden;
  height: 40px;
  font-weight: 500;
}

.step-body {
  padding: 10px !important;
  min-height: 40px !important;
}

.versions-popover {
  max-width: 430px !important;
  width: 430px !important;
}

.step-choices {
  font-size: 11pt;
  cursor: pointer;
  padding: 5px 8px;
  width: 100%;
}

.popover-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.step-choices:hover {
  background-color: #eee;
}

.stage {
  position: relative;
  margin-bottom: 1em;
  margin-top: 1.2em;
  width: 100%;
  padding: 30px;
}

.job-row-expanded {
  background-color: #2f3337 !important;
  color: white !important;
}

.highlighted-step {
  // background: #5ab8aa !important;
  background: linear-gradient(to right, rgb(164, 83, 201) 0%, rgb(74, 87, 207) 100%) !important;
  color: white !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border: 1px solid rgb(74, 87, 207);
}

.running-highlighted-step {
  box-shadow: 2px 2px 1px rgb(33, 150, 243) !important;
}

.success-highlighted-step {
  box-shadow: 2px 2px 1px rgb(34, 142, 33) !important;
}

.failed-highlighted-step {
  box-shadow: 2px 2px 1px rgb(255, 2, 4) !important;
}

.step-result-success-icon {
  color: #30816d !important;
}

.step-result-failed-icon {
  color: #ab2c26 !important;
}


.highlighted-row {
  border: 2px solid #a552c5 !important;
}

.code-file {
  color: #2196f3;
}

.code-file-highlight {
  color: white;
}

.CodeMirror pre {
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.bouncy-ball {
  width: 25px;
  height: 25px;

  position: relative;
  margin: 10px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #26B4FF !important;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.disabled-file {
  cursor: not-allowed;
}

.double-bounce-spinner {
  position: relative;
  margin: 0px !important;
  width: 25px !important;
  height: 25px !important;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0.0)
  }

  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.query-result-details {
  font-weight: bold;
  text-align: left;
  width: 100px;
  padding-right: 10px;
  color: #ccc;
  padding-top: 10px;
  display: inline-block;
}

.form-group {
  margin-bottom: 1.5rem;
}

.error {
  color: red;
}

.form-border {
  border: 1px solid #0a0d10;
}

.pulse {
  background: black;
  color: white;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.pulse.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

.pulse.blue {
  background: #03bdbf;
  box-shadow: 0 0 0 0 #0ea0a2;
  animation: pulse-blue 2s infinite;
}

.navbar-item-active {
  font-weight: bold;
  font-size: 16px;
}

.setup-btn {
  width: 200px;
}

.green-plus {
  cursor: pointer;
  color: green;
  padding-top: 7px;
}

.green-plus:hover {
  cursor: pointer;
  color: #006400;
}

.nav-link.active:hover {
  color: white !important;
}

.red-minus {
  cursor: pointer;
  color: red;
  padding-top: 7px;
}

.red-minus:hover {
  color: #c40000;
}

.email-sent {
  font-size: 12pt;
  margin-top: 40px;
  text-align: left;
  border-radius: 10px;
}

.main-btn {
  width: 200px;
}

.step-card {
  box-shadow: 0 1px 10px #00000040;
}

.job-open-body {
  box-shadow: 0px 4px 10px #00000040 !important;
}

.job-open-header {
  box-shadow: 0px -2px 5px #00000040 !important;
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.5);
}

.popover {
  box-shadow: 0px 1px 10px 0px #00000040 !important;
}

.dropdown-menu {
  box-shadow: 0 1px 10px #00000040 !important;
}

.overview-icon {
  vertical-align: bottom;
  padding-bottom: 6px;
  font-size: 1.1rem;
}

.sidenav-vertical .sidenav-menu .sidenav-link {
  padding-left: 1.8rem;
}

.mx-2 {
  margin-left: 0rem !important;
}

@media (min-width: 992px) {

  .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav .sidenav-vertical,
  .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical {
    width: 2.8rem !important;
  }
}

.sidenav-vertical .sidenav-menu .sidenav-menu .sidenav-link {
  padding-left: 3.5rem;
}

.sidenav-vertical,
.sidenav-vertical .sidenav-block,
.sidenav-vertical .sidenav-inner>.sidenav-item,
.sidenav-vertical .sidenav-inner>.sidenav-header {
  width: 22rem !important;
}

.sidenav-vertical .sidenav-icon {
  width: 0.6rem;
}

.column-hover:hover {
  cursor: pointer;
  background-color: #eee;
  border-radius: 15px;
  border: none;
}

.step-pill {
  background-color: #339FEE;
  color: white;
  border-radius: 50px;
  padding: 5px;
  height: 30px;
  width: 40px;
  text-align: center;
}


.integration-btn{
   font-size: 14pt;
   border-radius: 15px; 
   border: 1px solid #4f5155;
   padding: 10px;
   width: 180px;
   height: 90px;
}

.integration-btn:hover {
  box-shadow: 0px 1px 2px 0px #2ea0c9;
}

.spinner-border-delete:hover {
  color: white !important;
}

.integration-btn-connected {
  font-size: 14pt;
  border-radius: 15px;
  border: 1px solid #4f5155;
  padding: 25px;
  width: 250px;
  height: 125px;
}

.integration-btn-connected:hover {
  box-shadow: 0px 1px 2px 0px #7b7b7b;
}

.integration-title {
  font-size: 11pt;
  width: 100%;
  overflow-wrap: anywhere;
  padding-top: 15px;
  line-height: 1rem;
  font-weight: bold;
  color: black;
}

.package-option-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 24px;
  height: 150px;
}

.package-option-box:hover {
  box-shadow: 0px 1px 2px 0px #7b7b7b;
  cursor: pointer;
}

.package-option-selected {
  box-shadow: 0px 1px 2px 0px #2ea0c9;
}

.package-option-title {
  font-size: 12pt;
  font-weight: bold;
  padding-bottom: 5px;
}

.package-option-description {
  font-size: 10pt;
  height: 75px;
  overflow: hidden;
  width: 90%;
}

.package-option-selected-icon {
  color: #2ea0c9;
  text-align: right;
}

.package-option-icon {
  text-align: right;
}

.input-error {
  border: 1px solid red !important;
}

.package-profile-box {
  height: 110px;
  width: 220px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #7b9175;
}

.package-profile-box:hover {
  box-shadow: 0px 1px 2px 0px #7b9175;
  cursor: pointer;
}


.package-profile-title {
  padding-top: 25px;
  font-size: 13pt;
  font-weight: bold;
  padding-bottom: 5px;
  color: #7b9175;
}

.package-profile-description {
  font-size: 10pt;
}

.profile-secure-field {
  cursor: pointer;
  padding: 8px 10px;
  text-align: center;
  border-radius: 5px;
  width: 50px;
}

.automate-job-nav {
  cursor: pointer;
  margin-right: 15px;
  display: inline-block;
}

.automate-top-nav {
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
  border-right: 1px solid #ccc;
}

.variable-pills{
    margin: 5px 5px 5px 0px;
    font-size: small;
    padding: 7px 10px;
    border-radius: 5px;
}



.carve-scrollable-container {
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: auto;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 160, 162, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(14, 160, 162, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(14, 160, 162, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}